import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../context/auth";
import altExam from "../../assets/Exam-icon-1.png";
import { LanguageContext } from "../../context/LanguageContext";
import { useContext } from "react";
import { BiHistory, BiLogOut, BiTime } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import { ThreeCircles } from "react-loader-spinner";

const Exercises = ({ setExamId }) => {
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const { token, user, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const ExerciseData = useQuery(
    ["ExerciseDataApi", currentPage],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}exams?page=${currentPage}&exercises`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
        }
      },
    }
  );
  console.log("Last page: " + ExerciseData?.data?.data?.exercises?.last_page);
  console.log("CurrentPage: " + currentPage);

  useEffect(() => {
    window.scrollTo(0, 800);
  }, [ExerciseData]);
  return (
    <div>
      {ExerciseData.isFetching ? (
        <>
          {" "}
          <div className="h-[100vh]  flex items-start justify-center min-h-0">
            <div className="w-[100vh]">
              <ThreeCircles
                height="100"
                width="300"
                color="#f97316"
                ariaLabel="ThreeCircles-loading"
                visible={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="grid  lg:grid-cols-3 gap-10   ">
            {ExerciseData?.data?.data?.exercises?.data?.map((item) => {
              return (
                <div key={item}>
                  <div className=" rounded-lg shadow-lg py2 bg-white">
                    <div className="  p-2  text-left  text-xl mb-[1%]">
                      {" "}
                      <div ClassName=" w-[20%] h-[100%]">
                        {item.file.link ? (
                          <>
                            <img
                              className="object-cover h-60 w-full rounded-lg"
                              src={item.file.link}
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex  justify-center ">
                              <img
                                className=" h-60 w-fit rounded-lg"
                                src={altExam}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex">
                      <div className="w-[50%] flex flex-col ml-10 my-2">
                        <p className="text-base font-bold w-[100%]  ">
                          {" "}
                          {isEnglishLang ? (
                            <>
                              <p className="">
                                {item.subcategory.title.english}
                              </p>
                            </>
                          ) : isAmharicLang ? (
                            <>
                              <p className="">
                                {item.subcategory.title.amharic}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="">
                                {item.subcategory.title.oromiffa}
                              </p>
                            </>
                          )}
                        </p>
                        <div className="flex justify-start opacity-50">
                          <p className="mr-1">
                            <BiTime />
                          </p>
                          <p className="text-xs flex text-right">
                            {item.total_time} minutes
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-end w-[50%]">
                        <button
                          onClick={() => {
                            setExamId(item.id);

                            window.scrollTo(0, 0);
                            navigate(`/exam-info/${item.id}`);
                          }}
                          className="border rounded-lg bg-[#f97316] m-4 text-base px-4 py-1  text-black  "
                        >
                          Take exam
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center py-6 pr-10 ">
            {ExerciseData?.data?.data?.exercises?.links?.map((item, id) => {
              return (
                <div key={id}>
                  <div>
                    {item.label == "&laquo; Previous" ? (
                      <>
                        {currentPage == 1 ? (
                          <>
                            {" "}
                            <button className="mx-4 border p-2 px-4 bg-white hover:bg-[#f97316] hover:cursor-not-allowed hover:text-white text-[#f97316] border-[#f97316]   rounded-md">
                              Previous
                            </button>
                          </>
                        ) : (
                          <>
                            <>
                              {" "}
                              <button
                                className="mx-4 border p-2 px-4 bg-white hover:bg-[#f97316] hover:text-white text-[#f97316] border-[#f97316]   rounded-md"
                                onClick={() => {
                                  if (currentPage != 1) {
                                    setCurrentPage(currentPage - 1);
                                  }
                                }}
                              >
                                Previous
                              </button>
                            </>
                          </>
                        )}{" "}
                      </>
                    ) : item.label == "Next &raquo;" ? (
                      <>
                        {currentPage ==
                        ExerciseData?.data?.data?.exercises?.last_page ? (
                          <>
                            {" "}
                            <button className="mx-4 border p-2 px-4 bg-white hover:bg-[#f97316] hover:cursor-not-allowed hover:text-white text-[#f97316] border-[#f97316]   rounded-md">
                              Next
                            </button>
                          </>
                        ) : (
                          <button
                            className="mx-4 border p-2 px-4 bg-white hover:bg-[#f97316] hover:text-white text-[#f97316] border-[#f97316]   rounded-md"
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          >
                            Next
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {item.active == true ? (
                          <button
                            className="mx-4 border p-2 px-4 bg-[#f97316]  text-white rounded-md"
                            disabled
                          >
                            {item.label}
                          </button>
                        ) : (
                          <button
                            className="mx-4 border p-2 px-4 bg-white  hover:bg-[#f97316]  hover:text-white text-[#f97316] border-[#f97316]   rounded-md"
                            onClick={() => setCurrentPage(item.label)}
                          >
                            {item.label}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}{" "}
    </div>
  );
};

export default Exercises;
