import React, { useContext, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import ReactQuill from "react-quill";


import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../context/auth";


const MostRecent = () => {
    const navigate = useNavigate();
    const {
      isEnglishLang,
      setIsEnglishLang,
      isAmharicLang,
      setIsAmharicLang,
      isOromoLang,
      setIsOromoLang,
    } = useContext(LanguageContext);
    const { token, user, logout } = useAuth();

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };


    const HomePageData = useQuery(
        ["HomePageDataApi"],
        async () =>
          await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
            headers,
          }),
        {
          keepPreviousData: true,
          refetchOnWindowFocus: false,
          retry: false,
          enabled: !!token,
          onSuccess: (res) => {
            console.log(res);
          },
          onError: (res) => {
            if (res?.response?.status == 401) {
              logout();
            }
          },
        }
      );
  return (
    <div>
    <p className="font-bold text-2xl ">
      {isEnglishLang ? (
        <> Most Recent</>
      ) : isAmharicLang ? (
        <>የቅርብ ፅሁፎች</>
      ) : (
        <>Irra Dhiheenyaa</>
      )}
    </p>
    <div className=" ">
      <div>
        {" "}
        {HomePageData?.isFetching ? (
          <>
            {" "}
            <div className="h-[100vh]  flex items-start justify-center min-h-0">
              <div className="">
                <ThreeCircles
                  height="50"
                  width="100"
                  color="#f97316"
                  ariaLabel="ThreeCircles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {HomePageData?.data?.data?.popularPosts?.map(
              (item, i) => {
                return i < 4 ? (
                  <>
                    <div key={item}>
                      <div className="flex flex-col bg-white shadow-lg p-1 mt-4 mr-4  rounded-lg">
                        <div>
                          <div className="flex flex-col  p-2">
                            <div className="flex flex-row">
                              {" "}
                              <div
                                className="  w-[24%] h-[100%]
                              "
                              >
                                <img
                                  className=" object-cover h-20 w-full rounded-lg  "
                                  alt="unsplash"
                                  src={item?.thumbnail?.link}
                                />
                              </div>
                              <div className="flex flex-col  w-[100%]">
                                <div className="  text-sm text-left lg:text-xs ">
                                  {isEnglishLang ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/details/${item.id}`
                                          );
                                        }}
                                      >
                                        <p className="text-left pt-0 pl-0 ml-[5%] text-base lg:font-bold">
                                          {item.title.english}
                                        </p>
                                          <p className="lg:text-xs text-left lg:ml-[5%] opacity-50">
                                            {" "}
                                            {item.created_at}
                                          </p>

                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={
                                            item.body.english
                                          }
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>{" "}
                                    </>
                                  ) : isAmharicLang ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/details/${item.id}`
                                          );
                                        }}
                                      >
                                        <p className="  lg:font-bold">
                                          {item.title.english}
                                        </p>
                                        <p className="lg:text-xs text-left lg:ml-[5%]">
                                            {" "}
                                            {item.created_at}
                                          </p>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={
                                            item.body.amharic
                                          }
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/details/${item.id}`
                                          );
                                        }}
                                      >
                                        <p className="  lg:font-bold">
                                          {item.title.english}
                                        </p>
                                        <p className="lg:text-xs text-left lg:ml-[5%]">
                                            {" "}
                                            {item.created_at}
                                          </p>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden hover:underline"
                                          value={
                                            item.body.oromiffa
                                          }
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </button>
                                    </>
                                  )}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null;
              }
            )}{" "}
          </>
        )}
      </div>
    </div>
  </div>
  )
}

export default MostRecent