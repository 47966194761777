import React, { useContext, useEffect, useRef, useState } from "react";
import disableBrowserBackButton from "disable-browser-back-navigation";

import {
  useNavigate,
  useParams,
  UNSAFE_NavigationContext,
} from "react-router-dom";
import { browserHistory } from "react-router";
import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import unsplash from "../../assets/unsplash.png";
import unsplash2 from "../../assets/unsplash2.png";
import Footer from "../../components/Footer";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import { Button, Card, Menu, MenuItem } from "@mui/material";
import ClampLines from "react-clamp-lines";
import unsplash4 from "../../assets/unsplash4.png";
import unsplash5 from "../../assets/unsplash5.png";
import { LanguageContext } from "../../context/LanguageContext";
import ReactPlayer from "react-player";
import { ThreeCircles } from "react-loader-spinner";
import { BiLogOut } from "react-icons/bi";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import { GoThreeBars } from "react-icons/go";
import { MdHourglassDisabled } from "react-icons/md";

import { MdOutlineQuiz } from "react-icons/md";
import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.bubble.css";
import Question from "./Question";
import MyTimer from "./MyTimer";
import Score from "./Score";
import { ToastContainer, toast } from "react-toastify";
import { ExamContext } from "../../context/ExamContext";
import { usePreviousProps } from "@mui/utils";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DUMMY_DATA = {
  ExamName: "English Questions",
  Instruction: "Chose the best answer.",
  Questions: [
    {
      id: "0",
      question: "Whats is the verb of the brgin?",
      choices: { a: "luck", b: "form", c: "reset", d: "archery" },

      answer: "c",
    },
    {
      id: "1",
      question: "Whats is the verb of the after the king is not rested?",
      choices: { a: "luck", b: "form", c: "reset", d: "archery" },

      answer: "a",
    },
    {
      id: "2",
      question: "Whats is the verb of the derailed train?",
      choices: { a: "luck", b: "form", c: "reset", d: "archery" },

      answer: "d",
    },
    {
      id: "3",
      question: "Whats is the verb of the birds hopping in my tahoe?",
      choices: { a: "luck", b: "form", c: "reset", d: "archzfsdfsdery" },

      answer: "c",
    },
    {
      id: "4",
      question: "Whats is the verb of the brgixvn?",
      choices: { a: "luck", b: "form", c: "reset", d: "archery" },

      answer: "c",
    },
    {
      id: "5",
      question: "Whats is the verb of the class of 4992?",
      choices: { a: "luck", b: "form", c: "reset", d: "archery" },

      answer: "a",
    },
  ],
  numberOfQuestions: "6",
};

const ExamDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);
  const { exam, setExam } = useContext(ExamContext);
  const [isScholarship, setIsScolarship] = useState(true);
  const [isAmharic, setIsAmharic] = useState(false);
  const submitId = useRef(null);

  const [isEnglish, setIsEnglish] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isChinese, setIsChinese] = useState(false);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  const [after, setAfter] = useState(false);

  const { token, user, logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const [openQuit, setOpenQuit] = React.useState(false);
  const handleOpenQuit = () => setOpenQuit(true);
  const handleCloseQuit = () => setOpenQuit(false);

  const [openAttention, setOpenAttention] = React.useState(true);
  const handleOpenAttention = () => setOpenAttention(true);
  const handleCloseAttention = () => setOpenAttention(false);

  const [disabled, setDisabled] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const [score, setScore] = useState(0);
  const [touched, setTouched] = useState(false);
  const [finalized, setFinalized] = useState(0);

  const ExamData = JSON.parse(localStorage.getItem("ExamData"));
  console.log(ExamData);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  /*   const ExamData = useQuery(
    ["ExamDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  ); */
  console.log(ExamData?.data);
  const DetailData = useQuery(
    ["DetailDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}show-post/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: () => {
        //  console.log(categoryData?.data?.data?.data[1]?.name?.amharic);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [DetailData]);

  const SubmitHandler = () => {};
  const handleRadioClick = () => {
    setScore(7);
    setShowAnswer(true);
    setDisabled(true);
  };
  const time = new Date();
  const totalTime = ExamData?.data?.exam?.total_time * 60;
  console.log("TOtal_time: " + totalTime);
  time.setSeconds(time.getSeconds() + totalTime); //  minutes timer
  disableBrowserBackButton();
  window.onbeforeunload = function () {
    navigate("/exams");
  };

  const submitExamHandler = () => {
    submitExamMutationSubmitHandler();
  };

  const submitExamMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}exam-submit`,
        newData,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 403) {
          toast("Error");
        }
      },
    }
  );
  const submitExamMutationSubmitHandler = async (values) => {
    let formData = new FormData();
    formData.append("exam_id", submitId.current);
    formData.append("score", score);
    formData.append("finalized", finalized);

    console.log(score);

    try {
      submitExamMutation.mutate(formData, {
        onSuccess: () => {
          toast.success("success, exam submitted");
        },
        onError: (err) => {
          console.log({ err });
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    console.log("score from details " + score);
    submitId.current = ExamData?.data?.exam?.id;
    console.log(submitId.current);
    submitExamHandler();
  }, [touched]);

  useEffect(() => {
    return () => {
      setFinalized(1);
    };
  }, [submitCount == ExamData?.data?.exam?.questions_count]);

  return (
    <>
      {exam == false ? (
        <div>
          {() => {
            navigate("/exams");
            toast("Thank you for your time.");
          }}
        </div>
      ) : (
        <div className="w-[100%] h-[100vh]  overflow-scroll">
          <Modal
            open={openAttention}
            onClose={handleCloseAttention}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="absolute lg:top-[20%] left-[5%] lg:left-[20%] lg:translate-x-1/2  translate-y-1/2 width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
              <p className="font-bold text-red-500 text-2xl">Attention!</p>
              <p className="m-4 flex  text-xs lg:text-base flex-col">
                <p className="flex">
                  <p className="mt-1"> Do not refresh this page, you will</p>{" "}
                  <p className="text-red-500 px-1 mb-4 font-bold text-base lg:text-xl">
                    Quit{" "}
                  </p>{" "}
                  <p className="mt-1">this exam and</p>
                  <br />
                </p>
                <p>you will not be able to take this exam for 7 days.</p>
              </p>
              <div className="flex justify-end"></div>
            </div>
          </Modal>
          <div className="">
            <div className="flex font-inter mt-48 lg:mt-0  ">
              <div className="flex flex-col w-[100%] ">
                <div className="flex flex-row lg:ml-[4%]">
                  <div>
                    <button>
                      <img
                        className=" w-[90%] my-[4%] lg:m-[1%] ml-[6%] lg:ml-]|[0%] mt-[16%] lg:mt-[40%] "
                        alt="Vector"
                        src={vector2}
                      />
                    </button>
                  </div>
                  <div className="pl-[0%] pt-[8%] flex flex-col lg:pt-[3%] lg:pl-[0%] lg:w-[20%]">
                    <p className="font-inter  font-bold xs:text-4xl sm:text-xl md:text-xl lg:text-xl">
                      <button>ClassABCD</button>
                    </p>
                    <p className="hidden lg:flex font-inter xs:text-4xl sm:text-xl md:text-xl lg:text-xl  text-[#f97316] ">
                      <button>Learning Center</button>
                    </p>
                  </div>

                  <div className="lg:hidden flex pt-[0%] w-[20%] justify-end text-4xl text-[#f97316]">
                    <Button
                      sx={{ color: "#f97316", fontSize: "45px" }}
                      onClick={handleOpenBars}
                    >
                      <GoThreeBars />
                    </Button>
                    <Modal
                      open={openBars}
                      onClose={handleCloseBars}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <div className=" flex justify-center lg:hidden flex-row">
                          <div className="mx-[5%]">
                            <Button
                              className=" ml-[2%]  px-[4%]  my-[4%] rounded-lg flex flex-col"
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              <img alt="Vector" src={vector4} />
                              <p className="text-[#f97316] text-xs ">
                                Language{" "}
                              </p>
                            </Button>
                            <Menu
                              id="basic-menu"
                              sx={{
                                left: { xs: "0%", lg: "0%" },
                                top: { xs: "0%", lg: "-10%" },
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  setIsEnglishLang(true);
                                  setIsAmharicLang(false);
                                  setIsOromoLang(false);
                                }}
                              >
                                English
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  setIsEnglishLang(false);
                                  setIsAmharicLang(true);
                                  setIsOromoLang(true);
                                }}
                              >
                                Amharic
                              </MenuItem>

                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  setIsEnglishLang(false);
                                  setIsAmharicLang(false);
                                  setIsOromoLang(true);
                                }}
                              >
                                Oromiffa
                              </MenuItem>
                            </Menu>
                          </div>
                          <div className=" mt-[0%] ml-[6%] lg:mt-[3%] mx-[2%]  lg:mx-[2%]  ">
                            <button
                              className=" text-3xl lg:flex justify-center p-[25%] rounded-lg flex-col"
                              onClick={handleOpenQuit}
                            >
                              <MdHourglassDisabled />
                              <p className="text-xs flex  justify-center">
                                Quit
                              </p>
                            </button>

                            <div>
                              <Modal
                                open={openQuit}
                                onClose={handleCloseQuit}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <div className="absolute top-[20%] left-[20%] lg:left-[20%] lg:translate-x-1/2  translate-y-1/2 width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
                                  <p className="font-bold text-[#f97316]  text-2xl">
                                    Are you sure you want to quit this exam?
                                  </p>
                                  <p className="m-4">
                                    You will not be able to take this exam for 7
                                    days.
                                  </p>
                                  <div className="flex justify-end">
                                    <button
                                      className=" text-xl p-[2%] w-[30%]  rounded-lg flex-col bg-red-500"
                                      onClick={() => {
                                        setExam(false);
                                        navigate("/exams");
                                      }}
                                    >
                                      <p className="px-[25%] text-white ">
                                        Quit
                                      </p>
                                    </button>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                          </div>
                          <div className=" mt-[0%] ml-[6%] lg:mt-[3%] mx-[2%]  lg:mx-[2%]  ">
                            <button
                              className=" text-3xl lg:flex justify-center p-[15%] rounded-lg flex-col "
                              onClick={logout}
                            >
                              <BiLogOut />
                              <p className="text-xs flex  justify-center">
                                Logout
                              </p>
                            </button>
                          </div>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div className="w-[60%]"></div>
                  <div className="hidden  lg:flex mt-[6%] lg:mt-[2%] h-[40%] mx-[2%]  lg:mx-[2%]  ">
                    <div className=" hidden  p-[4%] lg:flex justify-end ">
                      <button
                        className="  rounded-lg  flex-col"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <img
                          className="w-[60%] ml-2"
                          alt="Vector"
                          src={vector4}
                        />
                        <p className="text-[#f97316] text-xs lowercase">
                          Language{" "}
                        </p>
                      </button>
                      <Menu
                        id="basic-menu"
                        sx={{
                          left: { xs: "0%", lg: "0%" },
                          top: { xs: "0%", lg: "-10%" },
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setIsEnglishLang(true);
                            setIsAmharicLang(false);
                            setIsOromoLang(false);
                          }}
                        >
                          English
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setIsEnglishLang(false);
                            setIsAmharicLang(true);
                            setIsOromoLang(true);
                          }}
                        >
                          Amharic
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setIsEnglishLang(false);
                            setIsAmharicLang(false);
                            setIsOromoLang(true);
                          }}
                        >
                          Oromiffa
                        </MenuItem>
                      </Menu>
                    </div>
                    <p className="text-4xl">|</p>
                    <button
                      className=" text-3xl lg:flex justify-center p-[3%] mr-2 ml-2 rounded-lg flex-col "
                      onClick={handleOpenQuit}
                    >
                      <MdHourglassDisabled />
                      <p className="text-xs flex  justify-center text-red-500 ml-1">
                        Quit
                      </p>
                    </button>
                    <div>
                      <Modal
                        open={openQuit}
                        onClose={handleCloseQuit}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div className="absolute top-[20%] left-[20%] lg:left-[20%] lg:translate-x-1/2  translate-y-1/2 width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
                          <p className="font-bold text-[#f97316] text-2xl">
                            Are you sure you want to quit this exam?
                          </p>
                          <p className="m-4">
                            You will not be able to take this exam for 7 days.
                          </p>{" "}
                          <div className="flex justify-end">
                            <button
                              className=" text-xl p-[2%] w-[30%]  rounded-lg flex-col bg-red-500"
                              onClick={() => {
                                setExam(false);
                                navigate("/exams");
                                toast("Thank you for your time.");
                              }}
                            >
                              <p className="px-[25%] text-white ">Quit</p>
                            </button>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <p className="text-4xl">|</p>
                    <button
                      className=" text-2xl lg:flex  text-center px-[10%] mr-10 pt-1 rounded-lg flex-col "
                      onClick={logout}
                    >
                      <div className="ml-1">
                        {" "}
                        <BiLogOut />
                      </div>
                      <p className="text-xs flex  text-center text-red-500">
                        Logout
                      </p>
                    </button>
                  </div>
                </div>

                <div className="lg:flex lg:flex-row lg:h-[70vh]">
                  <div className="lg:flex lg:flex-col lg:w-[90%] lg:pl-[10%]  lg:pr-[18%]">
                    {DetailData?.isFetching ? (
                      <>
                        {" "}
                        <div className="h-[100vh] flex items-center justify-center min-h-0">
                          <div ClassName="">
                            <ThreeCircles
                              height="100"
                              width="300"
                              color="#f97316"
                              ariaLabel="ThreeCircles-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-row overflow-y-scroll ">
                        <div>
                          <div className="flex justify-start lg:mt-0 font-semibold lg:justify-start lg:pl-[2%] mx-4 text-3xl my-[2%]">
                            Exam
                          </div>
                          <div className="flex justify-start lg:mt-0 font-semibold lg:justify-start lg:pl-[2%] mx-4 text-3xl my-[2%]">
                            {ExamData?.data?.exam?.name}
                          </div>
                          <p className="text-base p-4  font-semibold">
                            Choose the best answer.
                          </p>
                          <div className=" grid grid-cols-1   ">
                            {ExamData?.data?.exam?.questions?.map((item) => {
                              return (
                                <>
                                  <div key={item?.id}></div>
                                  <Question
                                    disabled={disabled}
                                    setDisabled={setDisabled}
                                    showAnswer={showAnswer}
                                    question={item}
                                    score={score}
                                    setScore={setScore}
                                    submitCount={submitCount}
                                    setSubmitCount={setSubmitCount}
                                    setTouched={setTouched}
                                    touched={touched}
                                  />
                                </>
                              );
                            })}

                            {/*
                          <div className="flex justify-end">
                            {" "}
                             <button
                              disabled
                              className="bg-[#00bbf0] w-[35%] lg:w-[15%] py-[2%] my-[4%] rounded-lg "
                            >
                              <p className=" text-white  lg:text-base">
                                Submiting
                              </p>
                            </button>
                          </div> */}
                            {showAnswer ? (
                              <>
                                {" "}
                                <div className="flex justify-end">
                                  <button
                                    onClick={() => {
                                      setExam(false);
                                      navigate("/exams");
                                      toast("Thank you for taking this exam ");
                                      localStorage.removeItem("ExamData");
                                    }}
                                    className="bg-[#00bbf0] w-[35%] lg:w-[15%] py-[2%] my-[4%] mr-[10%] rounded-lg"
                                  >
                                    <p className="text-white lg:text-base">
                                      Done
                                    </p>
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                {submitCount !=
                                ExamData?.data?.exam?.questions_count ? (
                                  <>
                                    {" "}
                                    <div className="flex justify-end">
                                      <p className="w-[100%] lg:w-[75%] py-[2%] pl-[2%] text-center my-[4%] mr-[1%] flex justify-end ">
                                        Please Answer all of the questions.{" "}
                                        {submitCount}/
                                        {ExamData?.data?.exam?.questions_count}{" "}
                                        answered.
                                      </p>
                                      <button
                                        disabled
                                        className="bg-[#00bbf0] w-[35%] lg:w-[15%] py-[2%] my-[4%] mr-[10%] rounded-lg opacity-50 hover:cursor-not-allowed"
                                      >
                                        <p className="text-white lg:text-base">
                                          Submit
                                        </p>
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex justify-end">
                                      <button
                                        onClick={() => {
                                          setShowAnswer(true);
                                          setDisabled(true);
                                          submitId.current =
                                            ExamData?.data?.exam?.id;
                                          console.log(submitId.current);
                                          submitExamHandler();
                                        }}
                                        className="bg-[#00bbf0] w-[35%] lg:w-[15%] py-[2%] my-[4%] mr-[10%] rounded-lg"
                                      >
                                        <p className="text-white lg:text-base">
                                          Submit
                                        </p>
                                      </button>
                                    </div>{" "}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        {!showAnswer ? (
                          <div className="fixed top-0 left-0 bg-white p-4 border border-black w-full lg:border-none lg:p-0 lg:top-[20%] lg:left-[75%] ">
                            <MyTimer
                              expiryTimestamp={time}
                              setDisabled={setDisabled}
                              setShowAnswer={setShowAnswer}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="fixed top-0 left-0 bg-white p-4 border border-black w-full lg:border-none lg:p-0 lg:top-[20%] lg:left-[75%] ">
                              <Score
                                score={score}
                                noOfQuestions={
                                  ExamData?.data?.exam?.questions_count
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExamDetails;
