import React from "react";
import { useTimer } from "react-timer-hook";

function MyTimer({ expiryTimestamp, setDisabled, setShowAnswer }) {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setShowAnswer(true);
      setDisabled(true);
    },
  });

  return (
    <div>
      <div className=" ">
        <p className="pl-2 font-semibold text-base lg:text-xl">Timer</p>
        <div className="flex flex-row justify-center lg:justify-start">
          <div className="flex text-center w-18 flex-col text-black bg-[#f97316]  p-4 rounded-md mx-2">
            {" "}
            <p className="text-2xl lg:text-4xl ">{hours}</p>
            <p className="text-sm lg:text-sm">Hours</p>
          </div>
          <div className="flex text-center w-18 lg:w-24 flex-col text-black bg-[#f97316] p-4 rounded-sm mx-2">
            {" "}
            <p className="text-2xl lg:text-4xl">{minutes}</p>
            <p className="text-sm lg:text-sm">Minutes</p>
          </div>
          <div className="flex text-center w-18 lg:w-24 flex-col text-black bg-[#f97316] p-4 rounded-sm mx-2">
            {" "}
            <p className="text-2xl lg:text-4xl">{seconds}</p>
            <p className="text-sm  lg:text-sm">Seconds</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyTimer;
