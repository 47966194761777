import React from "react";

const Pagination = ({item, currentPage, setCurrentPage, MainData }) => {
  return (
    <div>
       {item.label == "&laquo; Previous" ? (
                <>
                  {currentPage == 1 ? (
                    <>
                      {" "}
                      <button className="mx-4  p-2 px-4 bg-white hover:bg-[#f97316] hover:cursor-not-allowed hover:text-white text-[#f97316]   rounded-lg">
                        Previous
                      </button>
                    </>
                  ) : (
                    <>
                      <>
                        {" "}
                        <button
                          className="mx-4  p-2 px-4 bg-white hover:bg-[#f97316] hover:text-white text-[#f97316]    rounded-lg"
                          onClick={() => {
                            if (currentPage != 1) {
                              setCurrentPage(currentPage - 1);
                            }
                          }}
                        >
                          Previous
                        </button>
                      </>
                    </>
                  )}{" "}
                </>
              ) : item.label == "Next &raquo;" ? (
                <>
                  {currentPage == MainData?.data?.data?.last_page ? (
                    <>
                      {" "}
                      <button className="mx-4  p-2 px-4 bg-white hover:bg-[#f97316] hover:cursor-not-allowed hover:text-white text-[#f97316]   rounded-lg">
                        Next
                      </button>
                    </>
                  ) : (
                    <button
                      className="mx-4  p-2 px-4 bg-white hover:bg-[#f97316] hover:text-white text-[#f97316]   rounded-lg"
                      onClick={() => {
                        
                          setCurrentPage(currentPage + 1);
                        
                      }}
                    >
                      Next
                    </button>
                  )}
                </>
              ) : (
                <>
                  {item.active == true ? (
                    <button
                      className="mx-4 border p-2 px-4 bg-[#f97316]  text-white rounded-lg"
                      disabled
                    >
                      {item.label}
                    </button>
                  ) : (
                    <button
                      className="mx-4 border p-2 px-4 bg-white  hover:bg-[#f97316]  hover:text-white text-[#f97316] border-[#f97316]   rounded-lg"
                      onClick={() => setCurrentPage(item.label)}
                    >
                      {item.label}
                    </button>
                  )}
                </>
              )}
      
    </div>
  );
};

export default Pagination;
