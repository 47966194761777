import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import { ThreeCircles } from "react-loader-spinner";
import { useAuth } from "./context/auth";

import GoogleFontLoader from "react-google-font-loader";
import Footer from "./components/Footer";
import Home from "./pages/home/Home";
import SearchPage from "./pages/search/SearchPage";
import Details from "./pages/details/Details";
import { LanguageContext } from "./context/LanguageContext";
import { TokenContext } from "./context/TokenContext";

import Category from "./pages/category/Category";
import NavBar from "./components/NavBar";
import { CategoryContext } from "./context/CategoryContext";
import Exams from "./pages/exams/Exams";
import ExamInfo from "./pages/exams/ExamInfo";
import ExamDetails from "./pages/exams/ExamDetals";
import FooterWave from "../src/assets/FooterWave.png";

import { ExamContext } from "./context/ExamContext";
import { createBrowserHistory } from "@remix-run/router";
import History from "./pages/exams/History";
import { AccessContext } from "./context/AccessContext";
import AccessDenied from "./pages/exams/AccessDenied";
import ExerciseDetails from "./pages/exams/ExerciseDetails";
import WinnersList from "./pages/home/winnersList/WinnersList";

const customHistory = createBrowserHistory({
  backBehavior: "deny",
});
const App = () => {
  const { token, user, checked } = useAuth();
  const [isEnglishLang, setIsEnglishLang] = useState(true);
  const [isAmharicLang, setIsAmharicLang] = useState(false);
  const [isOromoLang, setIsOromoLang] = useState(false);
  const [myToken, setMyToken] = useState(null);
  const [category, setCategory] = useState(null);
  const [noAccess, setNoAccess] = useState(false);
  const [exam, setExam] = useState(false);
  console.log("Exam:" + exam);
  console.log("Access:" + noAccess);
  function LoginComp() {
    return (
      <>
        <Routes>
          <Route path="*" element={<Login />} />
        </Routes>
        <div className="h-[200%] lg:h-[20%]">
          <img className=" lg:w-[100%]  " alt="vector2" src={FooterWave} />
        </div>
      </>
    );
  }

  function HomeComp() {
    return (
      <>
        {" "}
        <AccessContext.Provider value={[noAccess, setNoAccess]}>
          <ExamContext.Provider value={{ exam, setExam }}>
            <CategoryContext.Provider value={{ category, setCategory }}>
              <TokenContext.Provider value={{ myToken, setMyToken }}>
                <LanguageContext.Provider
                  value={{
                    isEnglishLang,
                    setIsEnglishLang,
                    isAmharicLang,
                    setIsAmharicLang,
                    isOromoLang,
                    setIsOromoLang,
                  }}
                >
                  {" "}
                  <GoogleFontLoader
                    fonts={[
                      {
                        font: "Poppins",
                        weights: [300, "400i"],
                      },
                    ]}
                  />
                  <div className=" overflow-x">
                    <NavBar />
                    <Routes>
                      {" "}
                      <Route path="/login" element={<Login />} />
                      <Route path="/" element={<Home />} />
                      <Route path="/search" element={<SearchPage />} />
                      <Route path="/details/:id" element={<Details />} />
                      <Route path="/category/:id" element={<Category />} />
                      <Route path="/exams" element={<Exams />} />
                      <Route path="/exam-info/:id" element={<ExamInfo />} />
                      <Route path="/exam-details/:id" element={<Exams />} />
                      <Route path="/exams-history" element={<History />} />
                      <Route path="/access-denied" element={<AccessDenied />} />
                      <Route path="/winners-list" element={<WinnersList />} />
                    </Routes>
                    <Footer />
                  </div>
                </LanguageContext.Provider>
              </TokenContext.Provider>
            </CategoryContext.Provider>
          </ExamContext.Provider>
        </AccessContext.Provider>
      </>
    );
  }

  function ExamComp() {
    return (
      <>
        {" "}
        <ExamContext.Provider value={{ exam, setExam }}>
          <CategoryContext.Provider value={{ category, setCategory }}>
            <TokenContext.Provider value={{ myToken, setMyToken }}>
              <LanguageContext.Provider
                value={{
                  isEnglishLang,
                  setIsEnglishLang,
                  isAmharicLang,
                  setIsAmharicLang,
                  isOromoLang,
                  setIsOromoLang,
                }}
              >
                {" "}
                <GoogleFontLoader
                  fonts={[
                    {
                      font: "Poppins",
                      weights: [300, "400i"],
                    },
                  ]}
                />
                <div className=" overflow-x">
                  <Routes>
                    {" "}
                    <Route
                      history={customHistory}
                      path="/exam-details/:id"
                      element={<ExamDetails />}
                    />
                    <Route
                      history={customHistory}
                      path="/exercise-details/:id"
                      element={<ExerciseDetails />}
                    />
                  </Routes>
                </div>
              </LanguageContext.Provider>
            </TokenContext.Provider>
          </CategoryContext.Provider>
        </ExamContext.Provider>
      </>
    );
  }

  function RoutComp() {
    if (token && user && !exam) {
      return (
        <>
          <HomeComp />
        </>
      );
    } else if (token && user && exam) {
      return (
        <>
          <ExamComp />
        </>
      );
    } else {
      return <LoginComp />;
    }
  }
  return (
    <div>
      <>
        {checked ? (
          <RoutComp />
        ) : (
          <div className="h-44 flex items-center justify-center min-h-screen">
            <ThreeCircles
              height="200"
              width="200"
              color="#f97316"
              ariaLabel="ThreeCircles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default App;
