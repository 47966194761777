import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";

const Question = ({
  disabled,
  setDisabled,
  showAnswer,
  setShowAnswer,
  score,
  setScore,
  question,
  submitCount,
  setSubmitCount,
  setTouched,
  touched
}) => {
  var correct;
  if (question?.answer?.[1]) {
    question.answer.map((item) => {
      return (correct = item.text);
    });
  } else {
    correct = question?.answer[0]?.text;
  }

  const [answer, setAnswer] = useState(null);
  const [correctFound, setCorrectFound] = useState(false);
  const [counter, setCounter] = useState(null);
  const [innerDisabled, setInnerDisabled] = useState(false);

  //console.log("Your answer was " + answer + "Correct answer is " + correct);
  const handleRadio2Click = () => {
    setShowAnswer(true);
  };
  useEffect(() => {
    if (answer == correct && answer != undefined) {
      setScore(score + 1);
      setCorrectFound(true);
    }
  }, [answer]);

  return (
    <div>
      {" "}
      <div className="text-left p-6">
        <p className="text-sm  px-4 flex flex-row">
          <b className="pt-3">Q:</b>
          <ReactQuill
            value={question.question}
            readOnly={true}
            theme={"bubble"}
          />
        </p>

        <ul class=" w-full mb-4 text-sm font-medium text-gray-900  grid lg:lg:lg:grid-cols-2 gap-x-32 ">
          <li class="">
            <div value="a" class=" pl-3 py-2 ">
              {question?.choices?.map((option, index) => {
                return (
                  <div key={index}>
                    <input
                      name={option.id}
                      type="radio"
                      value={option.text}
                      checked={option.text === answer}
                      disabled={disabled}
                      onChange={(event) => {
                        if (correctFound == true) {
                          setScore(score - 1);
                          setCorrectFound(false);
                        }
                        setAnswer(option?.text);

                        question?.choices?.forEach((option) => {
                          if (option.text !== answer) {
                            option.checked = false;
                          }
                        });

                        if (counter != null) {
                        } else {
                          setSubmitCount(submitCount + 1);
                        }
                        setCounter(1);
                        setInnerDisabled(true);
                        setTouched(!touched);
                      }}
                      class="w-4 h-4 text-[#f97316] bg-gray-100 border-gray-300 focus:ring-[#f97316] "
                    />

                    <label class="w-full py-3 ml-2 text-sm font-medium text-gray-900  ">
                      {option.text}
                    </label>
                  </div>
                );
              })}
            </div>
          </li>
        </ul>
      </div>
      {showAnswer ? (
        <>
          {answer == correct ? (
            <div className=" w-full  flex flex-row transition duration-1000 ease-in-out translate-y-1  bg-blue-100">
              <p className="px-4"> Answer:</p>
              <div className=" flex flex-col">
                {question.answer?.map((item) => {
                  return <div key={item}> {item.text}</div>;
                })}
              </div>
            </div>
          ) : (
            <div className=" w-full flex  bg-red-100">
              <p className="px-4"> Answer:</p>
              <div className=" flex flex-col">
                {question.answer[0] ? (
                  <>
                    {question.answer?.map((item) => {
                      return <div key={item}> {item.text}</div>;
                    })}
                  </>
                ) : (
                  <p className="text-xs">No answer </p>
                )}
              </div>
            </div>
          )}
        </>
      ) : answer == null ? null : (
        <> </>
      )}
    </div>
  );
};

export default Question;
