import React from "react";

const Score = ({ score, noOfQuestions }) => {
  return (
    <div>
      <div className=" ">
        <p className="pl-2 font-semibold text-base lg:text-xl">Your Score</p>
        <div className="flex flex-row justify-center lg:justify-start">
          <div className="flex text-center w-48 flex-col text-black bg-[#f97316]  p-4 rounded-md mx-2">
            {" "}
            <p className="text-2xl lg:text-6xl ">
              {score}/{noOfQuestions}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Score;
