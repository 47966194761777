import React, { useContext, useState } from "react";

import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import { useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import ClampLines from "react-clamp-lines";
import { LanguageContext } from "../../context/LanguageContext";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { BiLogOut } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import { GoThreeBars } from "react-icons/go";
import Modal from "@mui/material/Modal";
import MultiClamp from "react-multi-clamp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import Winners from "../../components/Winners";
import laptops from "../../assets/Laptops.png";
import laptop from "../../assets/laptop.png";
import phone from "../../assets/phone.png";
import backpack from "../../assets/backPack.png";
import books from "../../assets/books.png";
import mobileCard from "../../assets/mobileCard.png";
import giftBox from "../../assets/GiftBox.png";

import Marquee from "react-fast-marquee";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Category = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);

  const [counter, setCounter] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { token, user, logout } = useAuth();

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const HomePageData = useQuery(
    ["HomePageDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );
  const CategoryPageData = useQuery(
    ["CategoryPageDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  const Category1 = useQuery(
    ["Category1Api", refresh],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}posts-by-category/${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        setRefresh(false);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  console.log(CategoryPageData?.data?.data);
  return (
    <>
      <div className="w-[100%] h-[100%]  bg-gray-100 overflow-hidden">
        <div className="">
          <div className="flex font-inter ">
            <div className="flex flex-col w-[100%] ">
              <div className="flex justify-center lg:justify-start lg:pl-[2%] text-xl my-[2%]">
                {HomePageData?.data?.data?.postByCategory?.map((item, i) => {
                  return item.id == id ? (
                    <>
                      <div key={item}>
                        <div className="    text-xl mb-[0%]">
                          {isEnglishLang ? (
                            <>
                              {" "}
                              <p ClassName="text-sm font-bold">
                                {item.title.english}
                              </p>
                            </>
                          ) : isAmharicLang ? (
                            <>
                              {" "}
                              <p ClassName="text-sm font-bold">
                                {item.title.amharic}
                              </p>
                            </>
                          ) : (
                            <>
                              {" "}
                              <p ClassName="text-sm font-bold">
                                {item.title.oromiffa}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null;
                })}
              </div>
              <div className="grid lg:grid-cols-4 mx-[4%] mb-[4%] lg:bg-white  ">
                {Category1.isFetching ? (
                  <>
                    {" "}
                    <div className="h-[100vh] flex items-start justify-center min-h-0">
                      <div ClassName="">
                        <ThreeCircles
                          height="100"
                          width="300"
                          color="#f97316"
                          ariaLabel="ThreeCircles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {Category1?.data?.data?.data?.map((item, i) => {
                      return (
                        <>
                          <div key={item}>
                            <div className="flex flex-col mt-4 mx-[4%] bg-white shadow-md  lg:shadow-none">
                              <button
                              className="text-left "
                                onClick={() => {
                                  navigate(`/details/${item.id}`);
                                }}
                              >
                                <div ClassName=" w-[24%] h-[100%]">
                                  <img
                                    className="object-cover h-60 w-full rounded-lg"
                                    alt="cover"
                                    src={item?.thumbnail?.link}
                                  />
                                </div>
                                {isEnglishLang ? (
                                  <>  <p className="font-bold pb-1">
                                  {item.title.english}
                                </p></>
                                ) : isAmharicLang ? (
                                  <>  <p className="font-bold pb-1">
                                  {item.title.amharic}
                                </p></>
                                ) : (
                                  <>  <p className="font-bold pb-1">
                                  {item.title.oromiffa}
                                </p></>
                                )}

                                <p className="text-xs">{item.category.created_at}</p>

                                {isEnglishLang ? (
                                  <>
                                    {" "}
                                   
                                    <p className="text-xs w-[100%]  ">
                                      <MultiClamp ellipsis="..." clamp={3}>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden"
                                          value={item.body.english}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </MultiClamp>
                                    </p>
                                  </>
                                ) : isAmharicLang ? (
                                  <>
                                    {" "}
                                    
                                    <p className="text-xs w-[100%]  ">
                                      <MultiClamp ellipsis="..." clamp={3}>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden"
                                          value={item.body.amharic}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </MultiClamp>
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                  
                                    <p className="text-xs w-[100%]  ">
                                      <MultiClamp ellipsis="..." clamp={3}>
                                        <ReactQuill
                                          className="max-h-12 overflow-hidden"
                                          value={item.body.oromiffa}
                                          readOnly={true}
                                          theme={"bubble"}
                                        />
                                      </MultiClamp>
                                    </p>
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex justify-center mt-[3%]">
            <img src={giftBox} />
          </div>
          <div>
            <p className="lg:text-2xl flex justify-center font-bold mx-[3%]">
              Test your skills, Win exciting prizes! Join now for a rewarding
              learning experience!
            </p>
          </div>
          <div className="flex justify-center ">
            <div className="flex  flex-row  lg:w-[80%] mb-[8%] h-56">
            <Marquee>
              <img
                className=" pb-[%]  pt-[%]  px-[15%] "
                alt="vector2"
                src={laptops}
              />

              <img
                className=" pb-[%] pt-[25%]  px-[15%] "
                alt="vector2"
                src={phone}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%] "
                alt="vector2"
                src={backpack}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%]"
                alt="vector2"
                src={books}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%]"
                alt="vector2"
                src={laptop}
              />
              <img
                className=" pb-[%] pt-[25%] px-[15%]"
                alt="vector2"
                src={mobileCard}
              />
              <img
                className=" pb-[%]  pt-[5%]  px-[15%] "
                alt="vector2"
                src={laptops}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%] "
                alt="vector2"
                src={phone}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%] "
                alt="vector2"
                src={backpack}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%]"
                alt="vector2"
                src={books}
              />
              <img
                className=" pb-[%] pt-[25%]  px-[15%]"
                alt="vector2"
                src={laptop}
              />
              <img
                className=" pb-[%] pt-[25%] px-[15%]"
                alt="vector2"
                src={mobileCard}
              />
              </Marquee>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Category;
