import React, { useContext, useState } from "react";
import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import { useNavigate } from "react-router-dom";
import unsplash3 from "../../assets/unsplash3.png";
import unsplash4 from "../../assets/unsplash4.png";
import unsplash5 from "../../assets/unsplash5.png";
import Footer from "../../components/Footer";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../../context/auth";
import ClampLines from "react-clamp-lines";

import laptops from "../../assets/Laptops.png";
import laptop from "../../assets/laptop.png";
import phone from "../../assets/phone.png";
import backpack from "../../assets/backPack.png";
import books from "../../assets/books.png";
import mobileCard from "../../assets/mobileCard.png";
import giftBox from "../../assets/GiftBox.png";
import { LanguageContext } from "../../context/LanguageContext";
import IconButton from "@mui/material/IconButton";
import { ThreeCircles } from "react-loader-spinner";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BiLogOut } from "react-icons/bi";

import { Box, FormControl, InputLabel, Select } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GoThreeBars } from "react-icons/go";
import { MdOutlineQuiz } from "react-icons/md";
import Modal from "@mui/material/Modal";
import ReactQuill from "react-quill";
import MultiClamp from "react-multi-clamp";
import "react-quill/dist/quill.bubble.css";
import MostRecent from "../../components/MostRecent";
import Winners from "../../components/Winners";

import Marquee from "react-fast-marquee";
import WinnersList from "./winnersList/WinnersList";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);

  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(true);
  const [counter, setCounter] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const ITEM_HEIGHT = 48;

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const { token, user, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const PopularData = useQuery(
    ["PopularDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}homepage?popular`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  const PostByCategoryData = useQuery(
    ["PostByCategoryDataApi"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}homepage?posts-by-category`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );

  return (
    <>
      <div className="w-[100%] h-[100%] bg-gray-100 overflow-hidden">
        <div className="">
          <div className="flex font-inter ">
            <div className="flex flex-col  ">
              <div className="lg:flex lg:flex-row  lg:pl-[6%] lg:justify-start ">
                <div className="lg:flex lg:flex-col lg:w-[65%]  pb-[25%] lg:pb-[0%] lg:pr-[5%] overfill-y-hidden ">
                  <div className="flex justify-center lg:justify-start lg:pl-[2%] text-2xl my-[0%] font-bold  ">
                    {isEnglishLang ? (
                      <> Most Popular</>
                    ) : isAmharicLang ? (
                      <>በጣም ታዋቂ</>
                    ) : (
                      <>Irra caalaa Jaallatamaa</>
                    )}
                  </div>
                  {PopularData?.isFetching ? (
                    <>
                      {" "}
                      <div className="h-[100vh]  flex items-start justify-center min-h-0">
                        <div className="w-[100vh]">
                          <ThreeCircles
                            height="100"
                            width="300"
                            color="#f97316"
                            ariaLabel="ThreeCircles-loading"
                            visible={true}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex justify-center flex-col lg:justify-start text-left rounded-lg lg:bg-white lg:px-4 shadow-lg">
                        <div
                          className=" m-[3%] lg:p-[0%] lg:w-[90%] lg:h-[100%]
"
                        >
                          <img
                            className=" object-cover h-96 w-full rounded-lg  "
                            alt="unsplash"
                            src={
                              PopularData?.data?.data?.popularPosts[0]
                                ?.thumbnail?.link
                            }
                          />
                        </div>

                        <div className="flex justify-center font-bold  lg:justify-start ml-[7%] text-base lg:text-lg my-[0%] lg:ml-[2%]">
                          {isEnglishLang ? (
                            <>
                              {
                                PopularData?.data?.data?.popularPosts[0]?.title
                                  .english
                              }
                            </>
                          ) : isAmharicLang ? (
                            <>
                              {
                                PopularData?.data?.data?.popularPosts[0]?.title
                                  .amharic
                              }
                            </>
                          ) : (
                            <>
                              {
                                PopularData?.data?.data?.popularPosts[0]?.title
                                  .oromiffa
                              }
                            </>
                          )}
                        </div>
                        <div className=" ml-[8%] text-xs lg:ml-[2%] lg:text-xs opacity-50 mb-[2%]">
                          {PopularData?.data?.data?.popularPosts[0]?.created_at}
                        </div>
                        <div className="flex justify-start text-lg lg:w-[90%]  lg:justify-start  lg:text-base mx-[6%] lg:mx-[2%] ">
                          {" "}
                          {isEnglishLang ? (
                            <>
                              <p className="max-h-20  overflow-hidden scrollbar-hide">
                                <ReactQuill
                                  value={
                                    PopularData?.data?.data?.popularPosts[0]
                                      ?.body.english
                                  }
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </p>
                            </>
                          ) : isAmharicLang ? (
                            <>
                              <p className="max-h-20  overflow-hidden scrollbar-hide">
                                <ReactQuill
                                  value={
                                    PopularData?.data?.data?.popularPosts[0]
                                      ?.body.amharic
                                  }
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="max-h-20  overflow-hidden scrollbar-hide">
                                <ReactQuill
                                  value={
                                    PopularData?.data?.data?.popularPosts[0]
                                      ?.body.oromiffa
                                  }
                                  readOnly={true}
                                  theme={"bubble"}
                                />
                              </p>
                            </>
                          )}
                        </div>
                        <div className="flex justify-start">
                          <button
                            className="flex justify-center text m-4 py-[2%] mb-[4%] bg-[#f97316] text-white rounded-lg w-[20%]"
                            onClick={() => {
                              navigate(
                                `/details/${PopularData?.data?.data?.popularPosts[0]?.id}`
                              );
                            }}
                          >
                            Learn More
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="   lg:w-[30%] lg:flex  lg:flex-col justify-start pb-10 ">
                  <Winners />
                  <button
                    onClick={() => navigate("/winners-list")}
                    className=" hover:underline font-bold text-[#f97316] pt-10"
                  >
                    View All
                  </button>
                </div>
              </div>
              <div className="flex justify-center">
                <div className=" bg-white  border flex flex-col justify-center lg:w-[50%] lg:h-fill border-black  lg:p-0  ">
                  {" "}
                  <p className="lg:text-xs flex justify-center font-bold mx-[3%]">
                    Test your skills, Win exciting prizes! Join now for a
                    rewarding learning experience!
                  </p>{" "}
                  <Marquee>
                    <img
                      className=" pb-[%]  pt-[%]  px-[15%] "
                      alt="vector2"
                      src={laptops}
                    />

                    <img
                      className=" pb-[%] pt-[25%]  px-[15%] "
                      alt="vector2"
                      src={phone}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%] "
                      alt="vector2"
                      src={backpack}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%]"
                      alt="vector2"
                      src={books}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%]"
                      alt="vector2"
                      src={laptop}
                    />
                    <img
                      className=" pb-[%] pt-[25%] px-[15%]"
                      alt="vector2"
                      src={mobileCard}
                    />
                    <img
                      className=" pb-[%]  pt-[5%]  px-[15%] "
                      alt="vector2"
                      src={laptops}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%] "
                      alt="vector2"
                      src={phone}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%] "
                      alt="vector2"
                      src={backpack}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%]"
                      alt="vector2"
                      src={books}
                    />
                    <img
                      className=" pb-[%] pt-[25%]  px-[15%]"
                      alt="vector2"
                      src={laptop}
                    />
                    <img
                      className=" pb-[%] pt-[25%] px-[15%]"
                      alt="vector2"
                      src={mobileCard}
                    />
                  </Marquee>
                </div>{" "}
              </div>
              <div className="lg:mx-[4%] mt-[0%]">
                <div className="flex justify-center pt-[4%] lg:pt-[0%] lg:justify-start py-[0%]   lg:mt-[6%] lg:pl-[2%]  text-2xl font-bold ">
                  {isEnglishLang ? (
                    <> Popular Posts</>
                  ) : isAmharicLang ? (
                    <>ታዋቂ ልጥፎች</>
                  ) : (
                    <>Barreeffamoota Beekamoo</>
                  )}
                </div>
                <div className="grid lg:grid-cols-3 gap-4">
                  {PopularData?.data?.data?.popularPosts?.map((item, i) => {
                    return i < 3 ? (
                      <div key={i}>
                        <div className="  ">
                          <div className="flex flex-col   lg:flex-row lg:text-xs ">
                            <>
                              <div key={item}>
                                <button
                                  className="flex flex-col lg:inline p-[2%] bg-white rounded-lg shadow-lg    mx-[2%] mb-[25%] lg:mb-[2%] lg:pb-[0%]    "
                                  onClick={() => {
                                    navigate(`/details/${item.id}`);
                                  }}
                                >
                                  <div className="flex   flex-col  lg:flex-col  lg:p-[1%]">
                                    <img
                                      className=" rounded-lg  lg:aspect-[4/2] object-cover"
                                      alt="unsplash"
                                      src={item?.thumbnail?.link}
                                    />

                                    <div className="flex ">
                                      <div className="flex flex-col w-[70%]">
                                        <div className="flex  justify-start text-base font-bold ">
                                          {isEnglishLang ? (
                                            <>
                                              <p className="">
                                                {item.title.english}
                                              </p>
                                            </>
                                          ) : isAmharicLang ? (
                                            <>
                                              <p className="">
                                                {item.title.amharic}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p className="">
                                                {item.title.oromiffa}
                                              </p>
                                            </>
                                          )}
                                        </div>{" "}
                                        <div>
                                          <p className="text-xs text-left opacity-50 ">
                                            {item.created_at}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="flex justify-end lg:pl-[6%] text-base font-thin  ">
                                        {isEnglishLang ? (
                                          <>
                                            {" "}
                                            <p className="">
                                              <b>
                                                {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                  (cat, id) => {
                                                    return (
                                                      <div key={id}>
                                                        {cat.id ==
                                                        item?.category_id ? (
                                                          <div className="border p-1 m-2 rounded-lg text-[#f97316] border-[#f97316]">
                                                            {cat.title.english}
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </b>
                                            </p>
                                          </>
                                        ) : isAmharicLang ? (
                                          <>
                                            {" "}
                                            <p className=" ">
                                              <b>
                                                {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                  (cat, i) => {
                                                    return (
                                                      <div key={i}>
                                                        {cat.id ==
                                                        item.category.id ? (
                                                          <div className="border p-1 m-2 rounded-lg text-[#f97316] border-[#f97316]">
                                                            {cat.title.amharic}
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </b>
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <p className="">
                                              <b>
                                                {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                  (cat, i) => {
                                                    return (
                                                      <div key={i}>
                                                        {cat.id ==
                                                        item.category.id ? (
                                                          <div className="border p-1 m-2 rounded-lg text-[#f97316] border-[#f97316]">
                                                            {cat.title.oromiffa}
                                                          </div>
                                                        ) : null}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </b>
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex flex-col ">
                                      <div className="  text-sm text-left lg:text-xs">
                                        {isEnglishLang ? (
                                          <>
                                            <MultiClamp
                                              ellipsis="..."
                                              clamp={3}
                                            >
                                              <ReactQuill
                                                className="max-h-0 overflow-hidden"
                                                value={item.body.english}
                                                readOnly={true}
                                                theme={"bubble"}
                                              />
                                            </MultiClamp>
                                          </>
                                        ) : isAmharicLang ? (
                                          <>
                                            <MultiClamp
                                              ellipsis="..."
                                              clamp={3}
                                            >
                                              <ReactQuill
                                                className="max-h-0 overflow-hidden"
                                                value={item.body.amharic}
                                                readOnly={true}
                                                theme={"bubble"}
                                              />
                                            </MultiClamp>
                                          </>
                                        ) : (
                                          <>
                                            <MultiClamp
                                              ellipsis="..."
                                              clamp={3}
                                            >
                                              <ReactQuill
                                                className="max-h-0 overflow-hidden"
                                                value={item.body.oromiffa}
                                                readOnly={true}
                                                theme={"bubble"}
                                              />
                                            </MultiClamp>
                                          </>
                                        )}
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </button>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}

                  {PostByCategoryData?.data?.data?.postByCategory?.map(
                    (item, i) => {
                      return (
                        <>
                          {!item.posts?.[0] ? null : (
                            <>
                              {" "}
                              <>
                                {item.posts?.map((item, i) => {
                                  return (
                                    <>
                                      <div className="">
                                        <div key={item}>
                                          <>
                                            {" "}
                                            <button
                                              className="flex flex-col lg:inline p-[2%] bg-white rounded-lg shadow-lg lg:p-[0%]  mx-[2%] mb-[25%] lg:mb-[2%] lg:pb-[0%]    "
                                              onClick={() => {
                                                navigate(`/details/${item.id}`);
                                              }}
                                            >
                                              <div className="flex  flex-col  lg:flex-col  lg:p-[2%]">
                                                <img
                                                  className=" rounded-lg  lg:aspect-[4/2] object-cover"
                                                  alt="unsplash"
                                                  src={item?.thumbnail?.link}
                                                />

                                                <div className="flex">
                                                  <div className="flex flex-col w-[70%]">
                                                    <div className="flex justify-start text-base font-bold ">
                                                      {isEnglishLang ? (
                                                        <>
                                                          <p className="">
                                                            {item.title.english}
                                                          </p>
                                                        </>
                                                      ) : isAmharicLang ? (
                                                        <>
                                                          <p className="">
                                                            {item.title.amharic}
                                                          </p>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <p className="">
                                                            {
                                                              item.title
                                                                .oromiffa
                                                            }
                                                          </p>
                                                        </>
                                                      )}
                                                    </div>
                                                    <div>
                                                      <p className="text-xs text-left opacity-50 ">
                                                        {item.created_at}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div className="flex justify-end lg:pl-[6%] text-base font-thin">
                                                    {isEnglishLang ? (
                                                      <>
                                                        {" "}
                                                        <p className="">
                                                          <b>
                                                            {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                              (cat, i) => {
                                                                return (
                                                                  <div key={i}>
                                                                    {cat.id ==
                                                                    item?.category_id ? (
                                                                      <div className="border p-1 m-2 rounded-lg text-[#f97316] border-[#f97316]">
                                                                        {
                                                                          cat
                                                                            .title
                                                                            .english
                                                                        }
                                                                      </div>
                                                                    ) : null}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </b>
                                                        </p>
                                                      </>
                                                    ) : isAmharicLang ? (
                                                      <>
                                                        {" "}
                                                        <p className=" ">
                                                          <b>
                                                            {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                              (cat, i) => {
                                                                return (
                                                                  <div key={i}>
                                                                    {cat.id ==
                                                                    item
                                                                      .category
                                                                      .id ? (
                                                                      <div className="border p-1 m-2 rounded-lg text-[#f97316] border-[#f97316]">
                                                                        {
                                                                          cat
                                                                            .title
                                                                            .amharic
                                                                        }
                                                                      </div>
                                                                    ) : null}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </b>
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        <p className="">
                                                          <b>
                                                            {PostByCategoryData?.data?.data?.postByCategory?.map(
                                                              (cat, i) => {
                                                                return (
                                                                  <div key={i}>
                                                                    {cat.id ==
                                                                    item
                                                                      .category
                                                                      .id ? (
                                                                      <div className="border p-1 m-2 rounded-lg text-[#f97316]  border-[#f97316]">
                                                                        {
                                                                          cat
                                                                            .title
                                                                            .oromiffa
                                                                        }
                                                                      </div>
                                                                    ) : null}
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </b>
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="flex flex-col ">
                                                  <div className="  text-sm text-left lg:text-xs">
                                                    {isEnglishLang ? (
                                                      <>
                                                        <MultiClamp
                                                          ellipsis="..."
                                                          clamp={3}
                                                        >
                                                          <ReactQuill
                                                            className="max-h-0 overflow-hidden"
                                                            value={
                                                              item.body.english
                                                            }
                                                            readOnly={true}
                                                            theme={"bubble"}
                                                          />
                                                        </MultiClamp>
                                                      </>
                                                    ) : isAmharicLang ? (
                                                      <>
                                                        <MultiClamp
                                                          ellipsis="..."
                                                          clamp={3}
                                                        >
                                                          <ReactQuill
                                                            className="max-h-0 overflow-hidden"
                                                            value={
                                                              item.body.amharic
                                                            }
                                                            readOnly={true}
                                                            theme={"bubble"}
                                                          />
                                                        </MultiClamp>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <MultiClamp
                                                          ellipsis="..."
                                                          clamp={3}
                                                        >
                                                          <ReactQuill
                                                            className="max-h-0 overflow-hidden"
                                                            value={
                                                              item.body.oromiffa
                                                            }
                                                            readOnly={true}
                                                            theme={"bubble"}
                                                          />
                                                        </MultiClamp>
                                                      </>
                                                    )}
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            </button>
                                          </>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            </>
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex justify-center mt-[3%]">
            <img src={giftBox} />
          </div>
          <div>
            <p className="lg:text-2xl flex justify-center font-bold mx-[3%]">
              Test your skills, Win exciting prizes! Join now for a rewarding
              learning experience!
            </p>
          </div>
          <div className="flex justify-center ">
            <div className="flex  flex-row  lg:w-[80%] mb-[8%] h-56">
              <Marquee>
                <img
                  className=" pb-[%]  pt-[%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />

                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
                <img
                  className=" pb-[%]  pt-[5%]  px-[15%] "
                  alt="vector2"
                  src={laptops}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={phone}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%] "
                  alt="vector2"
                  src={backpack}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={books}
                />
                <img
                  className=" pb-[%] pt-[25%]  px-[15%]"
                  alt="vector2"
                  src={laptop}
                />
                <img
                  className=" pb-[%] pt-[25%] px-[15%]"
                  alt="vector2"
                  src={mobileCard}
                />
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
