import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vector2 from "../../assets/Vector2.png";
import vector3 from "../../assets/Vector3.png";
import vector4 from "../../assets/Vector4.png";
import vector5 from "../../assets/Vector5.png";
import Footer from "../../components/Footer";

import moment from "moment";
import { useAuth } from "../../context/auth";
import { useMutation, useQuery } from "react-query";
import ClampLines from "react-clamp-lines";
import unsplash4 from "../../assets/unsplash4.png";
import axios from "axios";
import { toast } from "react-toastify";
import { ThreeCircles } from "react-loader-spinner";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineQuiz } from "react-icons/md";
import { LanguageContext } from "../../context/LanguageContext";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import Category from "../category/Category";
import { GoThreeBars } from "react-icons/go";
import { AiFillPlayCircle } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import MultiClamp from "react-multi-clamp";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { ExamContext } from "../../context/ExamContext";
import RelatedExams from "./RelatedExams";
import { AccessContext } from "../../context/AccessContext";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExamInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { token, user, logout } = useAuth();

  const [openError, setOpenError] = React.useState(false);
  const [isExercise, setIsExercise] = React.useState(false);
  const handleOpenError = () => setOpenError(true);
  const handleCloseError = () => setOpenError(false);
  const headers = {
    "Content-Type": "multipart/form-data",
    Accept: "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);
  const [related, setRelated] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sendRequest, setSendRequest] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const { exam, setExam } = useContext(ExamContext);
  const [search, setSearch] = useState(null);
  const [SearchApi, setSearchApi] = useState(null);
  const [menu, setMenu] = useState("Menu");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  /*   useEffect(() => {
    addExamHandler();
  }, []); */

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [openBars, setOpenBars] = useState(false);
  const handleOpenBars = () => setOpenBars(true);
  const handleCloseBars = () => setOpenBars(false);

  const ExamData = useQuery(
    ["ExamDataApi", related],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}exams/${id}`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        setRelated(null);
      },
      onError: (res) => {
        if (res?.response?.status == 401) {
          toast(res.message);
        }
      },
    }
  );

  const getExamStatus = useQuery(
    ["getExamStatusApi", related, sendRequest],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}exam-start?exam_id=${id}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        console.log(res);
        localStorage.setItem("ExamData", JSON.stringify(res));
      },
      onError: (err) => {
        if (err?.response?.status == 403) {
          setErrorMessage(JSON.stringify(err?.response?.data?.message));
          handleOpenError();
          setDisabled(true);
        }
      },
    }
  );
  //console.log(ExamData.data);

  const ExamDataCall = JSON.parse(localStorage.getItem("ExamData"));
  console.log(ExamDataCall);

  useEffect(() => {
    if (ExamDataCall?.data?.type == "exercise") {
      setIsExercise(true);
    }
    console.log(isExercise);
  }, [getExamStatus]);

  return (
    <>
      <div className="w-[100%] h-[100%]  overflow-hidden">
        {ExamData.isFetching ? (
          <>
            <div className="h-[100vh] flex items-start justify-center min-h-0">
              <div ClassName="">
                <ThreeCircles
                  height="100"
                  width="300"
                  color="#f97316"
                  ariaLabel="ThreeCircles-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <div className="flex font-inter bg-gray-100 ">
                <div className="flex flex-col w-[100%] ">
                  <div className=" flex ml-2 lg:ml-14 border border-[#f97316] w-[17%] lg:w-[7%]">
                    <p className="pt-1 pl-2 text-[#f97316] ">
                      <BiArrowBack />
                    </p>
                    <button className="" onClick={() => navigate("/exams")}>
                      back
                    </button>
                  </div>
                  <div className="lg:flex lg:flex-row lg:h-[50%] ">
                    <div className="lg:flex lg:flex-col lg:w-[70%]  lg:px-[5%] ">
                      <div className="flex flex-row ">
                        <div className="flex justify-center w-[70%] lg:justify-start lg:pl-[2%] text-3xl my-[2%]">
                          Exam Information
                        </div>
                        <div className=" hidden lg:flex justify-end">
                          {/*   <Button onClick={handleOpenError}>
                            {" "}
                            Error modal
                          </Button> */}
                          <Modal
                            open={openError}
                            onClose={handleCloseError}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <div className="absolute top-[40%] left-[0%] lg:left-[0%] lg:translate-x-1/2  translate-y-1/2 width-[50%] bg-white border-2 border-solid border-black rounded-lg shadow-lg p-4">
                              <p className="font-bold text-[#f97316] text-2xl">
                                You have already taken this exam
                              </p>
                              <p className="m-4">{errorMessage}</p>
                            </div>
                          </Modal>
                        </div>
                        <div className=" lg:w-[30%] w-[50%] lg:mt-0 mt-4   lg:mb-4 lg:flex lg:justify-end">
                          {disabled ? (
                            <>
                              <button
                                className=" text-xl p-[6%] text-white w-[50%]  rounded-lg flex flex-row px-[0%] justify-center lg:justify-center lg:pl-2 opacity-50 bg-[#f97316] hover:cursor-not-allowed"
                                disabled
                                onClick={() => {
                                  setSendRequest(1);
                                }}
                              >
                                <div className="pt-1 text-xl">
                                  <AiFillPlayCircle />
                                </div>
                                <p className="text-center text-white flex ">
                                  Start
                                </p>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className=" text-xl p-[6%] text-white w-[50%]  rounded-lg flex flex-row px-[0%] justify-center lg:justify-center lg:pl-2  bg-[#f97316]"
                                onClick={() => {
                                  if (isExercise) {
                                    navigate(`/exercise-details/${id}`);
                                    setExam(true);
                                    toast.success("success, exam started");
                                  } else {
                                    setSendRequest(1);
                                    navigate(`/exam-details/${id}`);
                                    setExam(true);
                                    toast.success("success, exam started");
                                  }
                                }}
                              >
                                <div className="pt-1 text-xl">
                                  <AiFillPlayCircle />
                                </div>
                                <p className="text-center text-white flex ">
                                  Start
                                </p>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="lg:flex lg:flex-row lg:h-[100%] lg:pl-[6%] lg:justify-end ">
                        <div className="lg:flex lg:flex-col lg:w-[100%]  pb-[25%] lg:pb-[0%] lg:pr-[18%]   ">
                          <div className="flex justify-center lg:justify-start lg:pl-[2%] text-2xl my-[2%] font-bold  "></div>

                          <div
                            className=" m-[3%] lg:m-[0%] lg:w-[100%] lg:h-[100%]
"
                          >
                            <img
                              className=" object-cover h-full w-full rounded-lg  "
                              alt="unsplash"
                              src={ExamData?.data?.data?.exam?.file.link}
                            />
                          </div>
                          <div className=" ml-[8%] text-xs lg:ml-[2%] lg:text-xs ">
                            {moment(
                              ExamData?.data?.data?.exam?.created_at
                            ).calendar()}
                          </div>
                          
                          <div className="flex justify-center font-bold  lg:justify-start ml-[7%] text-base lg:text-lg my-[1%] lg:ml-[2%]">
                       
                                  {ExamData?.data?.data?.exam?.name}
                             
                          </div>
                          <div className="flex justify-center text-sm lg:w-[90%]  lg:justify-start  lg:text-sm mx-[6%] lg:mx-[6%] ">
                            {" "}
                            <ReactQuill
                              className="max-h-full overflow-hidden"
                              value={ExamData?.data?.data?.exam?.description}
                              readOnly={true}
                              theme={"bubble"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" hidden  w-[30%] lg:flex  lg:flex-col justify-start ">
                      <RelatedExams id={id} setRelated={setRelated} />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" sm:mt-[0%] md:mt-[0%] lg:mb-[2%]"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ExamInfo;
