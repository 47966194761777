import React, { useContext, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import ReactQuill from "react-quill";

import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../context/auth";
import Vector2 from "../assets/Vector2.png";
import Badge from "../assets/Badge.svg";
import { RxDividerVertical } from "react-icons/rx";

const Winners = () => {
  const navigate = useNavigate();
  const {
    isEnglishLang,
    setIsEnglishLang,
    isAmharicLang,
    setIsAmharicLang,
    isOromoLang,
    setIsOromoLang,
  } = useContext(LanguageContext);
  const { token, user, logout } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const WinnersData = useQuery(
    ["WinnersDataApi"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}winners`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {},
      onError: (res) => {
        if (res?.response?.status == 401) {
          logout();
        }
      },
    }
  );
  return (
    <div>
      <p className="font-bold text-2xl flex justify-center lg:justify-start">
        {isEnglishLang ? (
          <>Exam Winners</>
        ) : isAmharicLang ? (
          <>የፈተና አሸናፊዎች</>
        ) : (
          <>Mo'attoota Qormaata</>
        )}
      </p>
      <div className=" ">
        <div>
          {" "}
          {WinnersData?.isFetching ? (
            <>
              {" "}
              <div className="h-[100vh]  flex items-start justify-center min-h-0">
                <div className="">
                  <ThreeCircles
                    height="50"
                    width="100"
                    color="#f97316"
                    ariaLabel="ThreeCircles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {WinnersData?.data?.data?.data?.map((item, i) => {
                return  (
                  <>
                    <div key={item}>
                      <div className="flex flex-col bg-white shadow-lg p-1 mt-4 mr-4  rounded-lg">
                        <div>
                          <div className="flex flex-col  p-2">
                            <div className="grid grid-cols-3 gap-4">
                              <div className="flex flex-row justify-start w-[100%]">
                                {" "}
                                <div
                                  className="  pt-4 w-[100%] h-[100%]
                              "
                                >
                                  {item?.prize_photo?.link ? (
                                    <>
                                      {" "}
                                      <img
                                        className=" object-cover h-20 w-full rounded-lg  "
                                        src={item?.winner_photo?.link}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <img
                                        className=" object-fit   rounded-lg  "
                                        src={Vector2}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className=" w-[100%] flex flex-col  justify-center ">
                                <div className="flex justify-center px-2">
                                  <img
                                    className=" object-cover h-[100%] w-[35%]   rounded-lg  "
                                    src={Badge}
                                  />
                                </div>
                                <p className="text-center  text-xs lg:font-bold">
                                  {item.name}
                                </p>
                              </div>{" "}
                              <div
                                className="  w-[100%] h-[100%] flex justify-start
                              "
                              >
                                <div className="text-7xl  font-thin opacity-50 pt-4 ">
                                  |
                                </div>
                                <div className="flex flex-col justify-center">
                                  <div className=" ">
                                    {item?.prize_photo?.link ? (
                                      <>
                                        <div className="w-[100%] ">
                                          <p className="text-center  text-xs opacity-50 font-bold">
                                            Prize
                                          </p>
                                          <img
                                            className=" object-cover h-20 w-[100%] rounded-lg  "
                                            src={item?.prize_photo?.link}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <p className="text-center pl-6 text-xs opacity-50 font-bold">
                                          Prize
                                        </p>
                                        <div>
                                          <img
                                            className=" object-cover h-[100%] pl-6 w-[100%] rounded-lg  "
                                            src={Badge}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Winners;
